/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseTypeMap from '../BaseTypeMap/BaseTypeMap';
import { BudgetTypeEnum, MONTHS } from '../../../pojo-helpers/Collections';
import { Calculator, STRING_CONF } from '../../../pojo-helpers/pojo-helper';
import MapHandler from '../MapHandler/MapHandler';
import { PerItemVariable } from '../../BudgetTypes/budget-types-index';

class PerItemVariableTypeMap extends BaseTypeMap {
  constructor(...args) {
    super(...args);
  }

  calculator(values, valueBreakdown, data) {
    let results = this.emptySet();

    try {
      values.forEach((value, indices) => {
        const {
          numberFormat,
          percentFormat,
        } = value;
        const { itemVariable } = valueBreakdown[indices];
        const singlePerItem = MONTHS.map((month, index) => {
          const percentFormatted = (percentFormat) ? (percentFormat / 100.0) : 0.0;
          const breakDownItem = (itemVariable === 'turns') ? Calculator.convertToFloat((data[itemVariable][index])) : Calculator.convertToFloat((data[itemVariable]));
          const result = Calculator.convertToFloat(numberFormat)
            * Math.round((Calculator.convertToFloat(percentFormatted))
              * breakDownItem);
          return {
            amount: Calculator.convertToCurrency(result),
            numericAmount: result,
            month,
          };
        });
        results = this.mergeSets(results, singlePerItem);
      });
      return results;
    } catch (e) {
      return results;
    }
  }

  display(values, valueBreakdown, handleNumberChange, handleSelectChange, generalData, setValues, setValueBreakdown) {
    return (
      <PerItemVariable
        handleNumberChange={handleNumberChange}
        labels={this.label.labels}
        labelHelpers={this.label.labelHelpers}
        values={values}
        valueBreakdown={valueBreakdown}
        handleSelectChange={handleSelectChange}
        setValues={setValues}
        setValueBreakdown={setValueBreakdown}
      />
    );
  }
}

MapHandler.register(
  BudgetTypeEnum.PerItemVariable,
  new PerItemVariableTypeMap(
    BudgetTypeEnum.PerItemVariable,
    {
      labels: [STRING_CONF.PERITEMVAR_LABEL_AMOUNT, STRING_CONF.PERITEMVAR_LABEL_PERCENT, STRING_CONF.PERITEMVAR_MENULIST_BREAKDOWN],
      labelHelpers: [STRING_CONF.PERITEMVAR_MENULIST_BREAKDOWN_HELPER],
    },
    [{ percentFormat: '0.00', numberFormat: '0.00' }],
    [{ itemVariable: 'turns' }],
  ),
);

export default PerItemVariableTypeMap;
