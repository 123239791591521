import React from 'react';
import { STRING_CONF } from '../../../pojo-helpers/pojo-helper';

function DevBuild() {
  return (
    <div className="flex justify-center text-5xl text-red-500">
      {STRING_CONF.DEV_MODE_WARNING}
    </div>
  );
}

export default DevBuild;
